import { FC } from 'react'

import { AcademicCapIcon } from '@heroicons/react/24/outline'
import { CardContent } from '@mui/material'
import Card from '@mui/material/Card'
import { isNil } from 'ramda'
import {
  ACLPrincipalMembership,
  ACLPrincipalMembershipGroup,
} from 'types/graphql'

import { GoalListCellItemType } from 'src/components/Goals/interfaces'
import MilestoneArrow from 'src/components/MilestoneArrow/MilestoneArrow'

import CardDetails from './CardDetails'
import CardTitle from './CardTitle'
interface MilestoneProps {
  onClick?: (milestoneId: number) => void
  milestone: GoalListCellItemType['childGoals'][0]
  isSelected?: boolean
}
const Milestone: FC<MilestoneProps> = ({ onClick, milestone, isSelected }) => {
  // Check if the milestone is a learner course

  // Set the milestone type icon
  const milestoneIcon = milestone?.attachedLearnerItemId ? (
    <AcademicCapIcon
      className={`h-4 w-4 text-gray-400 ${isSelected ? '!text-white' : ''}`}
    />
  ) : (
    <MilestoneArrow
      colorClass={`text-gray-400 ${isSelected ? '!text-white' : ''}`}
      size="small"
    />
  )

  // Set the milestone type label

  // Set the days remaining icon

  // Set the days remaining label

  // Collect the assigned Memberships and Membership Groups
  const objectiveACLMembershipPrincipals = milestone?.aclPrincipals.reduce(
    (objectiveAssignees, current) => {
      if (current?.principalType === 'MEMBERSHIP') {
        objectiveAssignees.memberships.push(
          current?.principal as ACLPrincipalMembership,
        )
      }

      if (current?.principalType === 'MEMBERSHIPGROUP') {
        objectiveAssignees.membershipGroups.push(
          current?.principal as ACLPrincipalMembershipGroup,
        )
      }

      return objectiveAssignees
    },
    {
      memberships: [] as ACLPrincipalMembership[],
      membershipGroups: [] as ACLPrincipalMembershipGroup[],
    },
  )

  const hasGroupsOrMembers =
    objectiveACLMembershipPrincipals.memberships?.length > 0 ||
    objectiveACLMembershipPrincipals.membershipGroups?.length > 0

  return (
    <div className="relative">
      <div
        className={
          'w-4 h-2 bg-gray-50 border-b border-b-gray-300 absolute left-[-16px] top-[26px] hidden sm:block'
        }
      ></div>

      <div
        className={`border border-gray-200 rounded-xl bg-white p-2  absolute left-[-48px] top-[16px] hidden sm:block ${
          isSelected ? '!bg-indigo-500 !text-white' : ''
        }`}
      >
        {milestoneIcon}
      </div>
      <Card
        onClick={() => {
          !isNil(onClick) && onClick(milestone?.id)
        }}
        className={`
        transition ease-in-out duration-300 bg-white relative rounded-none !p-0 border-b border-gray-200 shadow-none
        ${!isNil(onClick) && 'hover:cursor-pointer hover:bg-gray-50 '}
        ${isSelected && ' !bg-indigo-50 !border-indigo-600 '}
      `}
      >
        <CardContent className="!p-3">
          <div className="flex justify-between flex-col md:flex-row">
            <div className="flex-1">
              <CardTitle
                hasGroupsOrMembers={hasGroupsOrMembers}
                milestone={milestone}
              />
            </div>
            <div className="flex-1">
              <CardDetails
                milestone={milestone}
                hasGroupsOrMembers={hasGroupsOrMembers}
                objectiveACLMembershipPrincipals={
                  objectiveACLMembershipPrincipals
                }
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default Milestone
