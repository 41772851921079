import { FC } from 'react'

import { Avatar, AvatarGroup, Tooltip } from '@mui/material'

interface SingleAvatarProps {
  id: number
  title: string | JSX.Element[]
  name: string
  avatarUrl: string
}
const SingleAvatar: FC<SingleAvatarProps> = ({
  id,
  title,
  name,
  avatarUrl,
}) => {
  // IF the avatarURL === 'count', the name has been passed as the count value. otherwise Show first letter of member name
  const avatarLabel = avatarUrl === 'count' ? name : name[0]

  return (
    <Tooltip
      key={id}
      title={<span className="block py-0.5">{title}</span>}
      arrow
      componentsProps={{
        tooltip: { className: 'bg-gray-700 text-sm' },
        arrow: { className: 'text-gray-700' },
      }}
    >
      <Avatar
        alt={name}
        sx={{ width: 28, height: 28 }}
        src={avatarUrl ?? '.'}
        className="bg-gray-300 text-base text-gray-600"
      >
        {avatarLabel}
      </Avatar>
    </Tooltip>
  )
}

const AssignedMembers: FC<{
  members: any[]
  isGroup?: boolean
}> = ({ members, isGroup = false }) => {
  const formattedMemberships = !isGroup
    ? members
    : members.map((item) => {
        return {
          membership: {
            id: item?.membershipGroup?.id,
            user: {
              name: item?.membershipGroup?.name,
            },
          },
        }
      })
  // Default Avatar styling

  // Need to force some css styles
  const avatarGroupStyles = {
    '& .MuiAvatarGroup-avatar': {
      width: 28,
      height: 28,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      background: 'rgb(209, 213, 219)',
      color: 'rgb(75, 85, 99)',
    },
  }

  // If no members assigned, return unassigned avatar
  if (!formattedMemberships?.length) {
    return <></>
  }

  // AvatarGroup does not allow hover events on the (+4) chip, so we need to make our own.
  // Set the visible and hidden assignees
  const maxVisibleAvatars = 1

  const visibleAvatars = formattedMemberships.slice(0, maxVisibleAvatars)
  const hiddenAvatars = formattedMemberships.slice(maxVisibleAvatars)

  // Set the visible avatars
  const displayAvatarsWithTooltip = visibleAvatars.map((member) => (
    <SingleAvatar
      key={member.membership?.id}
      id={member.membership?.id}
      title={member.membership?.user?.name ?? 'Unknown Member'}
      name={member.membership?.user?.name ?? 'Unknown Member'}
      avatarUrl={member.membership?.user?.avatarUrl}
    />
  ))

  // Set the hidden avatar list
  const hiddenAvatarList = hiddenAvatars.map((member) => (
    <p key={member.membership?.id} className="py-0.5">
      {member.membership?.user?.name ?? 'Unknown Member'}
    </p>
  ))

  // The first assigned avatar that will be "hidden"
  const singleHiddenMembership = hiddenAvatars[0]?.membership

  return (
    <AvatarGroup sx={avatarGroupStyles}>
      {displayAvatarsWithTooltip}

      {/* One hidden avatar - show them on the page */}
      {hiddenAvatars.length === 1 && (
        <SingleAvatar
          id={singleHiddenMembership?.id}
          title={singleHiddenMembership?.user?.name ?? 'Unknown Member'}
          name={singleHiddenMembership?.user?.name ?? 'Unknown Member'}
          avatarUrl={singleHiddenMembership?.user?.avatarUrl}
        />
      )}

      {/* Many hidden avatars - show them in a tooltip */}
      {hiddenAvatars.length > 1 && (
        <SingleAvatar
          id={hiddenAvatars.length}
          title={hiddenAvatarList}
          name={'+' + hiddenAvatars.length.toString()}
          avatarUrl={'count'}
        />
      )}
    </AvatarGroup>
  )
}

export default AssignedMembers
