import React, { ReactNode } from 'react'

interface Props {
  className?: string
  colorClass?: string
  size?: 'small'
  children?: ReactNode
}

const MilestoneArrow: React.FC<Props> = ({
  className,
  children,
  colorClass,
  size,
}) => {
  const iconSize =
    size === 'small' ? 'min-w-[16px] min-h-[16px]' : 'min-w-[20px] min-h-[20px]'

  return (
    <div className={`${className} m-auto text-center`}>
      <svg
        className={`mr-.5 ${iconSize} ${colorClass}`}
        width="16"
        height="16"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="m27.945 95.961l-4.418-4.418 2.2109-2.2109c1.2188-1.2188 1.2188-3.2031 0-4.4219-17.055-17.055-17.055-44.812 0-61.867 8.2656-8.2656 19.254-12.82 30.938-12.82 11.684 0 22.668 4.5547 30.93 12.82 1.2188 1.2188 3.2031 1.2148 4.4219 0l2.2109-2.2109 4.418 4.418-2.2109 2.2109c-3.6562 3.6562-9.6055 3.6523-13.258 0-7.0859-7.0859-16.5-10.988-26.516-10.988s-19.43 3.9023-26.516 10.988c-14.617 14.621-14.617 38.414 0 53.031 3.6523 3.6562 3.6523 9.6016 0 13.258z"
            fill="currentColor"
          />
          <path
            d="m7.0195 1.1602l78.402 78.402-4.418 4.418-78.402-78.402z"
            fill="currentColor"
          />
          <path
            d="m77.508 88.035h-6.25v-18.234l18.223 0.011719-0.003907 6.25-11.969-0.007812z"
            fill="currentColor"
          />
          <path
            d="m86.344 96.875h-6.25v-18.227l18.223 0.003906-0.003906 6.25-11.969-0.003906z"
            fill="currentColor"
          />
          <path
            d="m7.9453 18.469h-6.25v-18.258l18.219 0.035156v6.25h-11.969z"
            fill="currentColor"
          />
          <path
            d="m29.344 85.492l-2.7891-5.5898 36.555-18.262 19.512-37.824 5.5586 2.8633-20.453 39.641z"
            fill="currentColor"
          />
        </g>
      </svg>
      {children}
    </div>
  )
}

export default MilestoneArrow
