import { FC } from 'react'

import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'

const ObjectiveDescription: FC<{ description: string }> = ({ description }) => {
  return (
    <div className="description-container sm:border-l border-gray-300 pl-4 py-4">
      <p className="pb-2 uppercase text-[10px] font-medium text-gray-600 tracking-widest ">
        Description
      </p>
      <div className="text-sm text-gray-700">
        <RichTextEditor readOnly defaultValue={description} />
      </div>
    </div>
  )
}

export default ObjectiveDescription
