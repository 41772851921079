import { FC } from 'react'

import { ClockIcon } from '@heroicons/react/24/outline'
import { Chip, Box, LinearProgress } from '@mui/material'
import { GoalStatusTypes } from 'api/src/common/enums'
import { goalStatusChangeToNew } from 'api/src/common/utils'
import dayjs from 'dayjs'
import { clamp } from 'ramda'
import {
  ACLPrincipalMembership,
  ACLPrincipalMembershipGroup,
} from 'types/graphql'

import { GoalListCellItemChildType } from 'src/components/Goals/interfaces'

import AssignedMembers from '../AssignedMembers'
interface CardDetailsProps {
  milestone: GoalListCellItemChildType
  hasGroupsOrMembers: boolean
  objectiveACLMembershipPrincipals: {
    memberships: ACLPrincipalMembership[]
    membershipGroups: ACLPrincipalMembershipGroup[]
  }
}
const CardDetails: FC<CardDetailsProps> = ({
  milestone,
  hasGroupsOrMembers,
  objectiveACLMembershipPrincipals,
}) => {
  const milestoneProgressValue = milestone?.percentProgress
  const milestoneStatus = milestone.currentStatus
  const milestoneIsComplete = milestoneStatus === GoalStatusTypes.completed
  const milestoneDueDate = dayjs(milestone?.dueDate)
  const milestoneCompletedDate = dayjs(milestone?.completedDate)
  let milestoneStatusColor = 'blue'
  if (milestoneStatus === GoalStatusTypes.completed) {
    // Completed - Green
    milestoneStatusColor = 'green'
  } else if (milestoneStatus === GoalStatusTypes.blocked) {
    // Blocked - Gray
    milestoneStatusColor = 'gray'
  } else if (milestoneStatus === GoalStatusTypes.overdue) {
    // Overdue - Red
    milestoneStatusColor = 'red'
  }
  let daysRemainingLabel = `${milestone?.diffDays} Day${
    milestone?.diffDays === 1 || milestone?.diffDays === -1 ? '' : 's'
  }`
  const daysRemainingIcon = !milestoneIsComplete ? (
    <ClockIcon
      className={`text-${milestoneStatusColor}-600 stroke-${milestoneStatusColor}-600 h-5 w-5`}
    />
  ) : null
  // If the milestone is complete and due date is after/equal to the completed date. Show milestone "On Time"
  if (
    milestoneIsComplete &&
    !milestoneDueDate.isBefore(milestoneCompletedDate)
  ) {
    daysRemainingLabel = 'On Time'
  }
  return (
    <div className="w-full flex flex-row items-center justify-between mt-2 md:mt-0 md:justify-end gap-4 ">
      {hasGroupsOrMembers && (
        <span className="items-center flex">
          <AssignedMembers
            isGroup={true}
            members={objectiveACLMembershipPrincipals.membershipGroups}
          />
          <AssignedMembers
            members={objectiveACLMembershipPrincipals.memberships}
          />
        </span>
      )}
      <Chip
        className={
          'bg-transparent cursor-pointer w-20 stroke-red-600 flex flex-end px-0'
        }
        icon={daysRemainingIcon}
        label={
          <p className="text-xs font-medium text-gray-700 pl-0.5">
            {daysRemainingLabel}
          </p>
        }
        sx={{
          '.MuiChip-label': {
            padding: 0,
          },
          '.MuiChip-icon': {
            margin: 0,
          },
        }}
      />
      <Box className="w-[130px] flex  flex-col">
        <div className="flex justify-between mb-0.5">
          <p
            className={`text-[11px] leading-4 text-${milestoneStatusColor}-600 text-left font-medium uppercase `}
          >
            {goalStatusChangeToNew(milestoneStatus as GoalStatusTypes)}
          </p>
          <p
            className="pl-2 text-xs leading-4 font-medium tracking-wider uppercase mt-0.5 w-10 text-right"
            data-testid={`milestone-progress-value-${milestone.goalTitle}-${milestoneProgressValue}`}
          >
            {milestoneProgressValue}%
          </p>
        </div>
        <LinearProgress
          variant="determinate"
          value={clamp(0, 100, milestoneProgressValue)}
          color="inherit"
          className={`w-full text-${milestoneStatusColor}-600 h-1.5 rounded`}
        />
      </Box>
    </div>
  )
}
export default CardDetails
